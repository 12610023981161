<template>
  <v-card>
       <v-card-title class="text-h4 font-weight-bold">แผนที่<v-spacer/><v-btn @click="$emit('onClose')" icon><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text>
            <AzureMap  class="azure-map"  :center="[long, lat]" :zoom="zoom" @ready="setMap">
        <AzureMapDataSource>
      <AzureMapPoint :longitude="long" :latitude="lat"/>
      <AzureMapSymbolLayer :options="targetSymbolLayerOptions"/>
    </AzureMapDataSource>
     </AzureMap>
      </v-card-text>
  </v-card>
</template>

<script>
import {
  AzureMap,
  AzureMapDataSource,
  AzureMapPoint,
  AzureMapSymbolLayer
} from 'vue-azure-maps'

export default {
  props: {
    lat: {
      type: Number,
      default: 0
    },
    long: {
      type: Number,
      default: 0
    }
  },
  components: {
    AzureMap,
    AzureMapDataSource,
    AzureMapPoint,
    AzureMapSymbolLayer
  },
  data () {
    return {
      map: null,
      zoom: 14,
      targetSymbolLayerOptions: {
        iconOptions: {
          image: 'pin-red'
        }
      }
    }
  },
  methods: {
    setMap (mapEvent) {
      this.map = mapEvent.map
      this.map.resize()
    }
  }
}
</script>

<style  scoped>
.azure-map {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
